import React from "react";
import { Edit, SimpleForm, TextInput, required, Toolbar } from "react-admin";
import { UserProfileSaveButton } from "../../components/user";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <UserProfileSaveButton />
  </Toolbar>
);

const ProfileEdit = ({ staticContext, record, ...props }) => {
  return (
    <Edit
      title="ra.menu.system_profile"
      id={localStorage.getItem("userId")}
      resource={"profile"}
      basePath={"/profile"}
      {...props}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput
          source="nickname"
          label="ra.sources.nickname"
          validate={required()}
        />
        <TextInput source="email" label="ra.sources.email" disabled />
        <TextInput
          source="new_password"
          label="ra.sources.password"
          type="password"
        />
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;
