import React from "react";
import { Resource } from "react-admin";

// monitor
import MonitorMap from "./pages/monitorManagement/monitor-map";
// noise
import NoiseAlertList from "./pages/noiseManagement/noise-alert";
import NoiseDataList from "./pages/noiseManagement/noise-data";
// system
import SystemUser from "./pages/systemManagement/user";
import SystemRole from "./pages/systemManagement/role";
import SystemRoute from "./pages/systemManagement/route";

import RemoveIcon from "@material-ui/icons/Remove";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import AirplayIcon from "@material-ui/icons/Airplay";
import GraphicEqIcon from '@material-ui/icons/GraphicEq';

const resources = [
  // 不顯示的目錄
  // 母目錄
  {
    name: "system_management",
    icon: SettingsTwoToneIcon,
    options: { label: "ra.menu.system_management" },
  },
  {
    name: "monitor_management",
    icon: AirplayIcon,
    options: { label: "ra.menu.monitor_management" },
  },
  {
    name: "noise_management",
    icon: GraphicEqIcon,
    options: { label: "ra.menu.noise_management" },
  },
  // 子目錄
  {
    name: "system/user",
    icon: RemoveIcon,
    action: SystemUser,
    options: {
      label: "ra.menu.system_user",
    },
  },
  {
    name: "system/role",
    icon: RemoveIcon,
    action: SystemRole,
    options: {
      label: "ra.menu.system_role",
    },
  },
  {
    name: "system/route",
    icon: RemoveIcon,
    action: SystemRoute,
    options: {
      label: "ra.menu.system_route",
    },
  },
  {
    name: "monitor",
    icon: RemoveIcon,
    action: MonitorMap,
    options: {
      label: "ra.menu.monitor_map",
    },
  },
  {
    name: "air_noise/alerts",
    icon: RemoveIcon,
    action: NoiseAlertList,
    options: {
      label: "ra.menu.noise_alert",
    },
  },
  {
    name: "air_noise/data",
    icon: RemoveIcon,
    action: NoiseDataList,
    options: {
      label: "ra.menu.noise_data",
    },
  },
];

const baseResources = [
  <Resource name="profile" options={{ isMenuParent: false }} />,
  <Resource name="system/route/parent" />,
];
export { resources, baseResources };
