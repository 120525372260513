import React, { Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  Filter,
  usePermissions,
  TopToolbar,
} from "react-admin";

import { CustomDateTimeField } from "../../../components/helper";
import { ExporterButton } from "../../../components/exporter";

const styles = {
  airNoiseDb: {
    minWidth: '70px',
    display: 'block'
  },
};

const NoiseDataListFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="ra.sources.mac" source="mac" alwaysOn />
      <DateInput
        label="ra.sources.start_time"
        source="start_time"
        alwaysOn
      />
      <DateInput
        label="ra.sources.end_time"
        source="end_time"
        alwaysOn
      />
    </Filter>
  );
};

const NoiseDataListActions = (props) => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      {permissions &&
        permissions.indexOf("air_noise/data/export") > -1 && (
          <ExporterButton
            props={props}
            url="air_noise/data/export"
            fileName="臭味歷史記錄"
            isCsv={false}
          />
        )}
    </TopToolbar>
  );
};

class NoiseDataList extends React.Component {
  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          empty={false}
          bulkActionButtons={false}
          title="ra.menu.noise_data"
          actions={<NoiseDataListActions />}
          filters={<NoiseDataListFilters />}
        >
          <Datagrid>
            <TextField
              source="id"
              label="ra.sources.id"
              sortable={false}
            />
            <TextField
              source="mac"
              label="ra.sources.mac"
              sortable={false}
            />
            <TextField
              className={classes.airNoiseDb}
              source="db"
              label="ra.sources.noise_db"
              sortable={false}
            />
            <TextField
              source="pm25"
              label="ra.sources.pm25"
              sortable={false}
            />
            <TextField
              source="pm10"
              label="ra.sources.pm10"
              sortable={false}
            />
            <TextField
              source="pm1"
              label="ra.sources.pm1"
              sortable={false}
            />
            <TextField
              source="h"
              label="ra.sources.humidity"
              sortable={false}
            />
            <TextField
              source="t"
              label="ra.sources.temperature"
              sortable={false}
            />
            <CustomDateTimeField
              source="date"
              label="ra.sources.insert_time"
              sortable={false}
            />
          </Datagrid>
        </List>
      </Fragment>
    );
  }
  handleClose = () => {
    this.props.push("/air_noise/data");
  };
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(NoiseDataList);
