import React, { Component } from "react";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.option = {
      autoplay: this.props?.autoplay || true,
      responsive: this.props?.responsive || true,
      controls: this.props?.controls || true,
      fluid: this.props?.fluid || true,
      muted: this.props?.muted || true,
      sources:[{src: this.props?.src || "", type: "application/x-mpegURL"}]
    }
  }

  render() {
    return (
      <video
        className="video-js vjs-big-play-centered"
        data-setup="{}"
        ref={this.player}
      />
    );
  }
  
  componentDidMount() {
    const videoNode = this.player.current;
    this.video = window.videojs(videoNode, this.option, () => {
      window.videojs.log('onPlayerReady', this);
    });
  }
  
  componentWillUnmount() {
      if (this.video) {
          this.video.dispose();
      }
  }
}

export default VideoPlayer;