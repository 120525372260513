import * as React from "react";
import { Admin, Layout, Resource } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "ra-core";
import { Route } from "react-router";
import TreeMenu from "@bb-tech/ra-treemenu";
import Dashboard from "./pages/dashboard";
import authProvider from "./authProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import translations from "./translations";

import LoginPage from "./LoginPage";
import ProfileEdit from "./pages/profile/ProfileEdit";
import UserMenuAppBar from "./pages/userMenu/UserMenuAppBar";

import "react-dual-listbox/lib/react-dual-listbox.css";
import "./App.css";

import { resources, baseResources } from "./resources";
import { apiHost } from "./components/common";

const i18nProvider = polyglotI18nProvider((locale) => translations["zh-TW"]);

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("userId", localStorage.getItem("userId"));
  options.headers.set("token", localStorage.getItem("token"));
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(`${apiHost}/api`, httpClient);

const fetchResources = async () => {
  return fetch(`${apiHost}/api/system/resources`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem("token"),
    }
  })
  .then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
    }
  })
  .then((json) => {
    localStorage.setItem("permissions", json.user_action_permissions);

    const resourcesTmp = [];
    resources.forEach((item) => {
      resourcesTmp.push(item.name);
    });
    let customResources = [];
    json.user_resources.forEach((item) => {
      const idx = resourcesTmp.indexOf(item.permission);
      if (idx > -1) {
        const action = resources[idx]?.action || {};
        let options = resources[idx].options;
        if (item.parent === "") {
          options.isMenuParent = true;
        } else {
          if (options.isMenuParent !== false) {
            options["menuParent"] = item.parent;
          }
        }
        customResources.push(
          <Resource
            name={resources[idx].name}
            icon={resources[idx].icon}
            {...action}
            options={options}
          />
        );
      }
    });
    customResources = customResources.concat(baseResources);
    return customResources;
  })
  .catch((error) => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
  });
};

const App = () => (
  <Admin
    loginPage={LoginPage}
    authProvider={authProvider}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    layout={(props) => (
      <Layout {...props} menu={TreeMenu} appBar={UserMenuAppBar} />
    )}
    customRoutes={[
      <Route key="profile" path="/profile" render={() => <ProfileEdit />} />,
    ]}
  >
    {fetchResources}
  </Admin>
);

export default App;
