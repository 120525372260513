import React, { Fragment } from "react";
import { Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";
import Select from "react-select";
import L from "leaflet"
import TaichungCity from "../../../TaichungCity.json";
import { apiHost, headers, streamHost } from "../../../components/common"
import VideoPlayer from "../../../components/VideoPlayer";

const styles = {};

// const goToShow = (id) => {
//   return (
//   `<div class="air_info">
//     <div><a href='/#/monitor/${id}/show'>前往檢視</a></div>
//   </div>`);
// }

class MonitorMap extends React.Component {
  constructor() {
    super();
    this.state = {
      timerID: null, // 保存定時器的 ID
      zoom: 14,
      data: [],
      area: "南屯區",
      position: [TaichungCity[8].Lat, TaichungCity[8].Lon],
      showModal: false,
      videoUrl: '',
      videoTitle: '',
      mac: "",
      db: 0, // 噪音
      pm25: 0, // pm2.5
      pm1: 0, // pm1
      pm10: 0, // pm10
      t: 0, // 溫度
      h: 0 // 濕度
    }
  }

  componentDidMount() {
    document.addEventListener('showModalEvent', this.handleShowModalEvent);

    // 引入地圖
    this.map = L.map('map', {
      center: this.state.position,
      zoom: this.state.zoom
    });

    // 引入圖資
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.getAreaData(this.state.area);
  }

  componentDidUpdate() {
    this.map.eachLayer((layer) => {
      if (layer.dragging) {
        layer.remove();
      }
    });
    
    this.map.panTo([this.state.position[0], this.state.position[1]]);
    this.map.setView([this.state.position[0], this.state.position[1]], this.state.zoom);

    if (this.state.data.length > 0) {
      this.state.data.forEach((item) => {
        const popup = L.popup()
        .setContent(this.goToShow(item));

        const LeafIcon = L.Icon.extend({
          options: {
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]},
        });
        const icon = new LeafIcon({
          iconUrl: "/security-camera.svg",
        })
        new L.marker([item.lat, item.lon], { icon }).bindPopup(popup, { closeButton: false }).addTo(this.map);
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('showModalEvent', this.handleShowModalEvent);
    clearInterval(this.state.timerID); // 清除定時器
  }

  getAreaData = (area) => {
    const url = `${apiHost}/api/monitor/suburb/${area}`;
    fetch(url, { method: "GET", headers: headers(true, true)})
    .then((rep) => rep.json())
    .then((rep) => {
      let lat = "";
      let lon = "";
      TaichungCity.forEach((item) => {
        if (item.AreaName === area) {
          lat = item.Lat;
          lon = item.Lon
        }
      });
      this.setState({ data: rep });
      this.setState({ position: [lat, lon] });
    });
  }

  getAirNoiseRealTimeData = () => {
    const url = `${apiHost}/api/air_noise/real_time/${this.state.mac}`;
    fetch(url, { method: "GET", headers: headers(true, true)})
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.setState({
          db: data.db,
          pm25: data.pm25,
          pm1: data.pm1,
          pm10: data.pm10,
          t: data.t,
          h: data.h
        });
      })
      .catch(error => console.error('Error fetching sensor data:', error));
  }

  goToShow = (monitor) => {
    const monitors = new Set();
    for(let i = 0; i < monitor.monitor.length; i++) {
      if (monitor.monitor[i].is_display) {
        monitors.add(`${streamHost}/${monitor.monitor[i].number}/index.m3u8`);
      }
    }
    
    return (
    `<div class="air_info">
      <div><button class="view" onclick="viewVideo('${[...monitors].join(",")}', '${monitor.station_name}', '${monitor.mac}');">檢視</button></div>
    </div>`);
  }

  handleShowModalEvent = (event) => {
    const { videoUrl, videoTitle, mac } = event.detail;
    this.setState({ showModal: true, videoUrl, videoTitle, mac });
    this.setState({
      timerID: setInterval(this.getAirNoiseRealTimeData, 1000) // 1秒更新一次數據
    });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
    clearInterval(this.state.timerID); // 清除定時器
  }

  render() {
    const { showModal, videoUrl, videoTitle, db, pm25, pm1, pm10, t, h } = this.state;
    const videos = videoUrl.split(",");

    const areaOptions = () => {
      return TaichungCity.map((area) => {
        return { value: area.AreaName, label: area.AreaName }
      });
    }

    const handleInputChange = (selectedOption) => {
      this.setState({ area: selectedOption.value });
      this.getAreaData(selectedOption.value);
    };

    return (
      <Fragment>
        <div id="map"></div>
        <div className="select-box">
          <Select
            className="area-select"
            isSearchable={true}
            defaultValue={areaOptions()[8]}
            options={areaOptions()}
            onChange={handleInputChange}
          />
        </div>
        <Modal id="videoModal" show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
              <Modal.Title>{videoTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="air-noise">
              <div className="noise-wrapper">
                <label className="noise-label">
                  <img className="noise-img" alt="噪音" src="noise.png" />
                </label>
                <span className="noise-value">{db}</span>
                <span className="noise-unit">dB</span>
              </div>
              <div className="pm25-wrapper">
                <label className="pm25-label">
                  <img className="pm25-img" alt="pm2.5" src="pm25.png" />
                </label>
                <span className="pm25-value">{pm25}</span>
                <span className="pm25-unit">μg/m³</span>
              </div>
              <div className="pm1-wrapper">
                <label className="pm1-label">
                  <img className="pm1-img" alt="pm1.0" src="pm1.png" />
                </label>
                <span className="pm1-value">{pm1}</span>
                <span className="pm1-unit">μg/m³</span>
              </div>
              <div className="pm10-wrapper">
                <label className="pm10-label">
                  <img className="pm10-img" alt="pm10" src="pm10.png" />
                </label>
                <span className="pm10-value">{pm10}</span>
                <span className="pm10-unit">μg/m³</span>
              </div>
              <div className="temperature-wrapper">
                <label className="temperature-label">
                  <img className="temperature-img" alt="溫度" src="temperature.png" />
                </label>
                <span className="temperature-value">{t}</span>
                <span className="temperature-unit">°C</span>
              </div>
              <div className="humidity-wrapper">
                <label className="humidity-label">
                  <img className="humidity-img" alt="濕度" src="humidity.png" />
                </label>
                <span className="humidity-value">{h}</span>
                <span className="humidity-unit">％RH</span>
              </div>
            </div>
            <div>
              {videos.map((video) => <VideoPlayer src={video}/>)}
            </div>
          </Modal.Body>
        </Modal> 
      </Fragment>
    );
  }
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(MonitorMap);
