import React, { Fragment } from "react";
import { useState } from "react";
import { Button } from "react-admin";

import { Dialog, DialogContent, DialogActions } from "@material-ui/core";

import { TimestampFormat } from "./date";

import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";

const CustomDateTimeField = ({ record, source }) => {
  let time = "";
  if (record && record[source]) {
    time = TimestampFormat(record[source]);
  }
  return time;
};

const MultiShowImage = ({ record, source, maxSize }) => {
  return (
    <Fragment>
      {record && record[source] && record[source].length > 0
        ? record[source].map((image, idx) =>
            idx < maxSize ? (
              <div style={{ padding: "5px" }}>
                <ShowImage record={{ img: image, id: idx }} source={source} />
              </div>
            ) : (
              ""
            )
          )
        : ""}
    </Fragment>
  );
};

const ShowImage = ({ record, source }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const alt = record ? record.id + "-" + source : "";
  const src = record ? record[source] : "";

  // const handleDownloadImg = async () => {
  //   const url = await GetS3DownloadUrl(src);
  //   await fetch(url)
  //     .then((res) => res.blob())
  //     .then(async (blob) => {
  //       const type = await GetFileMimeType(blob);
  //       var filename = src.substring(src.lastIndexOf("/") + 1);
  //       DownloadBlobFile(blob, `${filename}.${type.ext}`);
  //     });
  // };

  const render =
    record && record[source] ? (
      <Fragment>
        <img
          alt={alt}
          src={src}
          className="image-default-width pointer"
          onClick={handleClick}
        />
        <Dialog onClose={handleDialogClose} open={open}>
          <DialogContent dividers>
            <img alt={alt} src={src} class="image-dialog-width" />
          </DialogContent>
          <DialogActions>
            {/* <Button
              label="ra.action.download"
              onClick={handleDownloadImg}
            ></Button> */}
            <Button
              label="ra.action.cancel"
              onClick={handleDialogClose}
            ></Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    ) : (
      ""
    );
  return render;
};

const ShowDetail = ({ record, source, length }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const detail = String(record[source]);
  const render =
    record && record[source] && detail.length > length ? (
      <Fragment>
        {detail.slice(0, length) + "..."}
        <SearchIcon className="pointer" onClick={handleClick} />
        <Dialog onClose={handleDialogClose} open={open}>
          <DialogContent dividers>{record[source]}</DialogContent>
          <DialogActions>
            <Button
              label="ra.action.cancel"
              onClick={handleDialogClose}
            ></Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    ) : (
      record[source]
    );
  return render;
};

const DownloadBlobFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const DownloadFile = (videoPath) => {
  const a = document.createElement('a');
  a.href = videoPath; // 使用记录中的视频路径
  a.download = videoPath.split('/').pop(); // 从路径中提取文件名
  a.target = '_blank'; // 在新标签页中打开
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};


const ShowMultiMedia = ({ record, source }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleDownloadMulti = async () => {
    record[source].forEach(item => {
      DownloadFile(item);
    });
  };

  const render =
    record && record[source] ? (
      <Fragment>
        <Button label="檢視影像" onClick={handleClick}><VisibilityIcon /></Button>
        <Dialog onClose={handleDialogClose} open={open}>
          <DialogContent dividers>
            <ShowMedia record={record} source={source} />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.download" onClick={handleDownloadMulti}></Button>
            <Button label="ra.action.cancel" onClick={handleDialogClose}></Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    ) : (
      ""
    );
  return render;
};

const ShowMedia = ({ record, source }) => {
  return (
    <Fragment>
      {record && record[source] && record[source].length > 0
        ? record[source].map((item, idx) => (
            <div>
              <div
                style={{
                  margin: "10px 0 10px 0",
                }}
              >
                <video width="320" height="240" controls>
                  <source src={item} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          ))
        : ""}
    </Fragment>
  );
};


export { CustomDateTimeField, MultiShowImage, ShowImage, ShowDetail, DownloadBlobFile, DownloadFile, ShowMultiMedia, ShowMedia };
