import moment from "moment-timezone";

const TIMEZONE = "Asia/Taipei";

const DateTimeFormat = (dateTime) => {
    if (dateTime) {
        return moment(dateTime).tz(TIMEZONE).format('YYYY-MM-DDTHH:mm');
    }
    return "";
}

const DateTimeParser = (dateTime) => {
    if (dateTime) {
        return moment(dateTime).unix() * 1000;
        //return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
    }
    return "";
}

const TimestampFormat = (timestamp) => {
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
}

/*
 * 取得當日timestamp
 */
const getTodayTimestamp = (tz = TIMEZONE) => {
    return moment().tz(tz).valueOf();
}

// 取得天數
const getDays = (datetime, tz = TIMEZONE) => {
    const today = getTodayTimestamp();
    const time = moment(datetime).tz(tz).valueOf();
    const days = Math.floor((time - today) / (24 * 3600 * 1000))
    return days;
}

export {
    DateTimeFormat,
    DateTimeParser,
    TimestampFormat,
    getTodayTimestamp,
    getDays
};