import React, { Fragment } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  useNotify,
  useRefresh,
  fetchStart,
  fetchEnd,
  Button,
  Confirm,
} from "react-admin";
import SaveIcon from "@material-ui/icons/Save";

import { apiHost, headers } from "../common";

const UserProfileSaveButton = ({ record }) => {
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    dispatch(fetchStart());
    const nickname = document.getElementById("nickname").value;
    const newPassword = document.getElementById("new_password").value;
    const updatedRecord = { nickname, newPassword };
    fetch(`${apiHost}/api/profile/${record.id}`, {
      method: "PUT",
      body: JSON.stringify(updatedRecord),
      headers: headers(),
    })
      .then(() => {
        if (newPassword) {
          localStorage.removeItem("userId");
          localStorage.removeItem("token");
          localStorage.removeItem("permissions");
        }
        refresh();
      })
      .catch((e) => {
        notify("User Profile Error", "warning");
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
        notify("Update User Profile Success");
      });
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    handleSubmit();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        label="Save"
        onClick={handleClick}
        disabled={loading}
        variant="contained"
      >
        <SaveIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="用戶資料"
        content={"確認更新用戶資料?"}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      ></Confirm>
    </Fragment>
  );
};

export default UserProfileSaveButton;
