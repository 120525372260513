import React, { Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { Drawer, withStyles } from "@material-ui/core";
import { Route } from "react-router";
import compose from "recompose/compose";

import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  EditButton,
  Button,
  translate,
} from "react-admin";

import SystemRoleCreate from "./SystemRoleCreate";
import SystemRoleEdit from "./SystemRoleEdit";

import { CustomDateTimeField } from "../../../components/helper";

import SettingsIcon from "@material-ui/icons/Settings";

const styles = {
  drawerContent: {
    width: 300,
  },
};

const CustomTextField = translate(({ record, source, translate }) => {
  let str = (
    <span className="badge bg-green">{translate("ra.sources.enable")}</span>
  );
  if (record && record[source] === 0) {
    str = <span className="badge">{translate("ra.sources.disable")}</span>;
  }
  return str;
});

const OperationButton = ({ basePath, record }) => {
  return (
    <TopToolbar style={{ justifyContent: "flex-start" }}>
      <EditButton label="ra.action.edit" basePath={basePath} record={record} />
      <Button
        label="ra.action.deploy"
        component={Link}
        to={{ pathname: `/system/role/${record.id}/show` }}
      >
        <SettingsIcon />
      </Button>
    </TopToolbar>
  );
};

const SystemRoleActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton label="ra.action.create" basePath={basePath} />
  </TopToolbar>
);

class SystemRoleList extends React.Component {
  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          empty={false}
          bulkActionButtons={false}
          title="ra.menu.system_role"
          actions={<SystemRoleActions />}
        >
          <Datagrid>
            <TextField
              source="id"
              label="ra.sources.role_id"
              sortable={false}
            />
            <TextField
              source="role_name"
              label="ra.sources.role_name"
              sortable={false}
            />
            <CustomTextField
              source="active_status"
              label="ra.sources.status"
              sortable={false}
            />
            <CustomDateTimeField
              source="create_time"
              label="ra.sources.insert_time"
              sortable={false}
            />
            <OperationButton />
          </Datagrid>
        </List>
        <Route path="/system/role/create">
          {({ match }) => (
            <Drawer open={!!match} onClose={this.handleClose} anchor="right">
              <SystemRoleCreate
                className={classes.drawerContent}
                onCancel={this.handleClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
        <Route path="/system/role/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== "create";

            return (
              <Drawer open={isMatch} onClose={this.handleClose} anchor="right">
                {isMatch ? (
                  <SystemRoleEdit
                    className={classes.drawerContent}
                    id={match.params.id}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }
  handleClose = () => {
    this.props.push("/system/role");
  };
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(SystemRoleList);
