import React from "react";
import Button from "@material-ui/core/Button";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SelectInput,
  useTranslate,
} from "react-admin";

import { OperateCallback } from "../../../components/OperateCallback";

const validateEdition = (values) => {
  const errors = {};
  if (!values.role_name) {
    errors.role_name = "The role name is required";
  }
  if (!values.active_status && values.active_status !== 0) {
    errors.active_status = "The active status is required";
  }
  return errors;
};

const EditToolbar = ({ onCancel, ...props }) => {
  const translate = useTranslate();
  return (
    <Toolbar {...props}>
      <OperateCallback props={props} operate="edit" />
      <Button onClick={onCancel}>{translate("ra.action.cancel")}</Button>
    </Toolbar>
  );
};

const SystemRoleEdit = ({ onCancel, ...props }) => (
  <Edit title=" " {...props}>
    <SimpleForm
      validate={validateEdition}
      toolbar={<EditToolbar onCancel={onCancel} />}
    >
      <TextInput disabled source="id" />
      <TextInput source="role_name" label="ra.sources.role_name" />
      <SelectInput
        source="active_status"
        label="ra.sources.status"
        choices={[
          { id: 1, name: "ra.sources.enable" },
          { id: 0, name: "ra.sources.disable" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default SystemRoleEdit;
