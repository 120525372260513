module.exports = {
  ra: {
    action: {
      add: "增加",
      add_filter: "增加檢索",
      app_version_unblock: "解除屏蔽",
      back: "退回",
      bulk_actions: "選中%{smart_count}項",
      cancel: "取消",
      clear_input_value: "清除值",
      clone: "複製",
      confirm: "確認",
      create: "新建",
      delete: "刪除",
      deploy: "設置",
      download: "下載",
      edit: "編輯",
      export: "輸出",
      list: "列表",
      push_now: "立即推播",
      refresh: "重新整理",
      relieve: "解除",
      remove: "刪除",
      remove_filter: "移除檢索",
      save: "保存",
      search: "搜尋",
      set_topic: "話題配置",
      show: "查看",
      sort: "排序",
      undo: "恢復",
      view: "檢視",
      view_comment: "查看評論",
      view_details: "查看詳情",
      view_note: "查看筆記",
      view_sub_comment: "查看子評論",
    },
    auth: {
      logout: "登出",
      password: "密碼",
      sign_in: "登錄",
      sign_in_error: "驗證失敗, 請重試",
      user_menu: "設置",
      username: "用戶名",
    },
    boolean: {
      false: "否",
      true: "是",
    },
    components: {
      activity: {
        delete: {
          content: "確認刪除該活動?",
          title: "活動",
        },
        enable: {
          title: "活動狀態",
        },
      },
      app_version: {
        confirm: {
          content: "確認解除屏蔽?",
          title: "App版本控管",
        },
      },
      approve: "通過",
      category: {
        delete: {
          content: "確認刪除該分區?",
          title: "分區",
        },
        enable: {
          title: "分區狀態",
        },
      },
      check_disable: "確認停用?",
      check_enable: "確認啟用?",
      comment: {
        delete: {
          content: "確認刪除此評論?",
          title: "評論",
        },
      },
      note: {
        dialog: {
          title: "%{title} 筆記",
        },
      },
      note_pending: {
        confirm: {
          content: "確認%{status} [%{title}] 筆記?",
          title: "待審核筆記",
        },
      },
      reject: "退回",
    },
    input: {
      file: {
        upload_several: "將多文件拖至此上傳，或點擊選取所有需要上傳的文件",
        upload_single: "將文件拖至此上傳, 或點擊這裡選取文件",
      },
      image: {
        upload_several: "將多圖片拖至此上傳，或點擊選取所有需要上傳的圖片",
        upload_single: "將圖片拖至此上傳, 或點擊這裡選取圖片",
      },
      references: {
        all_missing: "未找到參考數據.",
        many_missing: "至少有一條參考數據不再可用.",
        single_missing: "關聯的參考數據不再可用.",
      },
    },
    menu: {
      monitor_management: "監視器管理",
      monitor_map: "監視器-地圖",
      noise_management: "噪音管理",
      noise_alert: "噪音超標紀錄",
      noise_data: "噪音歷史紀錄",
      system_management: "系統管理",
      system_profile: "用戶資訊",
      system_role: "角色列表",
      system_route: "權限列表",
      system_user: "用戶列表"
    },
    message: {
      about: "關於",
      are_you_sure: "您確定?",
      bulk_delete_content:
        "您確定要刪除 %{smart_count} 項? |||| 您確定要刪除 %{name}?",
      bulk_delete_title: "刪除 %{name} |||| 刪除 %{smart_count}項 %{name} ",
      delete_content: "您確定要刪除該項目?",
      delete_title: "刪除 %{name} #%{id}",
      details: "詳細",
      error: "網頁錯誤，您的請求無法完成",
      invalid_form: "表單輸入無效. 請檢查錯誤提示",
      loading: "頁面讀取中, 請稍候",
      no: "否",
      not_found: "您輸入了錯誤的網址或者錯誤的鏈接.",
      yes: "是",
    },
    navigation: {
      next: "向後",
      no_more_results: "頁碼 %{page} 超出末頁. 試試上一頁.",
      no_results: "結果為空",
      page_out_from_begin: "已到最前頁",
      page_out_from_end: "已到最末頁",
      page_out_of_boundaries: "頁碼 %{page} 超出末頁",
      page_range_info: "%{offsetBegin}-%{offsetEnd} / %{total}",
      page_rows_per_page: "每頁資料數:",
      prev: "向前",
      skip_nav: "跳過導航",
    },
    notification: {
      bad_item: "不正確的條目",
      canceled: "取消動作",
      category_not_select: "分區未選",
      clear_audit_version_error: "解除屏蔽失敗",
      create_category_notify: "若要新增分區請聯絡技術人員!",
      created: "項目已新建",
      data_provider_error: "dataProvider錯誤. 請檢查console的詳細信息.",
      deleted: "項目已刪除 |||| %{smart_count} 項條目已刪除",
      http_error: "與服務通信出錯",
      item_doesnt_exist: "條目不存在",
      logged_out: "已登出，請重新登入",
      updated: "項目已更新 |||| %{smart_count} 項條目已更新",
    },
    page: {
      create: "新建 %{name}",
      dashboard: "概覽",
      edit: "%{name} #%{id}",
      error: "出現錯誤",
      list: "%{name} 列表",
      loading: "加載中",
      not_found: "未發現",
      show: "%{name} #%{id}",
      empty: "不存在",
    },
    sources: {
      account: "帳號",
      action_log: "操作記錄",
      action_time: "操作時間",
      all: "全部",
      avatar: "頭像",
      clean_team_monitor: "監視器(清潔隊)",
      company: "廠商",
      create_time: "新增時間",
      data: "資料",
      data_time: "收錄時間",
      delete: "刪除",
      description: "介紹",
      direct_url: "跳轉連結",
      disable: "禁用",
      display: "顯示位置",
      effective_time: "生效時間",
      email: "E-mail",
      enable: "啟用",
      end_time: "結束時間",
      humidity: "濕度 (％RH)",
      id: "ID",
      insert_time: "加入時間",
      ip: "IP",
      is_menu: "是否為目錄",
      is_permissions: "是否為權限",
      mac: "Mac",
      monitor_name: "監視器名稱",
      nickname: "暱稱",
      no: "否",
      noise_db: "噪音值(dB)",
      normal: "正常",
      online: "線上",
      operator: "操作者",
      order: "排序",
      parent: "母階層",
      password: "密碼",
      path: "路徑",
      permissions: "權限",
      permissions_path: "權限路徑",
      pm1: "PM1 (μg/m³)",
      pm10: "PM10 (μg/m³)",
      pm25: "PM2.5 (μg/m³)",
      role: "角色",
      role_id: "角色ID",
      role_name: "角色名稱",
      rtsp_url: "RTSP路徑",
      start_time: "開始時間",
      station_name: "站名",
      status: "狀態",
      temperature: "溫度 (°C)",
      user: "用戶",
      user_id: "用戶ID",
      video: "影片",
      weight: "權重",
      yes: "是"
    },
    validation: {
      email: "必須是有效的郵箱",
      expiration_time_required: "過期日期未填",
      maxLength: "最多 %{max} 個字",
      maxValue: "必須小於 %{max}",
      minLength: "最少 %{min} 個字",
      minValue: "必須大於 %{min}",
      number: "必須為數字",
      object_id: "請輸入 %{len} 位英數",
      oneOf: "必須為: %{options}其中一項",
      punishment_reason_required: "違規類型必填",
      range_time: "範圍日期：%{day}",
      regex: "必須符合指定的格式 (regexp): %{pattern}",
      required: "必填",
    },
  },
};
