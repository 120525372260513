import React, { Fragment } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  useNotify,
  useRedirect,
  useRefresh,
  fetchStart,
  fetchEnd,
  Confirm,
  Button,
} from "react-admin";
import { apiHost, headers } from "../common";

const useStyles = makeStyles({
  enableButton: {
    color: "#00a65a !important",
  },
  disableButton: {
    color: "#dd4b39 !important",
  },
});

const SystemRouteEnableButton = ({ basePath, record }) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    dispatch(fetchStart());

    const updatedRecord = { status: record.deleted === 1 ? 0 : 1 };
    fetch(`${apiHost}/api/system/route/status/${record.id}`, {
      method: "PUT",
      body: JSON.stringify(updatedRecord),
      headers: headers(),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        if (data.message !== "") {
          notify(data.message);
        }
        redirect(basePath);
        refresh();
      })
      .catch((e) => {
        notify("System permissions status change Error", "warning");
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    handleSubmit();
    setOpen(false);
  };

  const isDeleted = record.deleted === 1 ? 0 : 1;
  const label =
    record.deleted === 1 ? "ra.sources.enable" : "ra.sources.disable";
  const content =
    record.deleted === 1
      ? `ra.components.check_enable`
      : `ra.components.check_disable`;

  const classes = useStyles();
  return (
    <Fragment>
      <Button
        label={label}
        onClick={handleClick}
        className={isDeleted ? classes.disableButton : classes.enableButton}
        disabled={loading}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.components.system_route.confirm.title"
        content={content}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      ></Confirm>
    </Fragment>
  );
};

export default SystemRouteEnableButton;
