import React from "react";
import Button from "@material-ui/core/Button";
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  translate,
  SelectInput,
  NumberInput,
  ReferenceInput,
} from "react-admin";

import { OperateCallback } from "../../../components/OperateCallback";

const validateCreation = (values) => {
  const errors = {};
  if (!values.order) {
    errors.order = ["The order is required"];
  }
  return errors;
};

const CreateToolbar = translate(({ onCancel, translate, ...props }) => {
  return (
    <Toolbar {...props}>
      <OperateCallback props={props} operate="create" />
      <Button onClick={onCancel}>{translate("ra.action.cancel")}</Button>
    </Toolbar>
  );
});

const SystemRouteCreate = ({ onCancel, ...props }) => (
  <Create title=" " {...props}>
    <SimpleForm
      validate={validateCreation}
      toolbar={<CreateToolbar onCancel={onCancel} />}
    >
      <TextInput source="permissions" label="ra.sources.permissions_path" />
      <ReferenceInput
        source="parent"
        reference="system/route/parent"
        label="ra.sources.parent"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="description"
        label="ra.sources.description"
        multiline={true}
        fullWidth={true}
      />
      <NumberInput source="order" label="ra.sources.order" />
      <SelectInput
        source="isMenu"
        label="ra.sources.is_menu"
        choices={[
          { id: 1, name: "ra.sources.yes" },
          { id: 0, name: "ra.sources.no" },
        ]}
      />
      <SelectInput
        source="isPermissions"
        label="ra.sources.is_permissions"
        choices={[
          { id: 1, name: "ra.sources.yes" },
          { id: 0, name: "ra.sources.no" },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default SystemRouteCreate;
