const apiHost = window._env_.API_HOST
  ? window._env_.API_HOST
  : "http://127.0.0.1:8002";

const streamHost = window._env_.STREAM_HOST
  ? window._env_.STREAM_HOST
  : "http://127.0.0.1:8004";

const headers = (IsJson = true, isAuth = true) => {
  const headers = {};
  if (IsJson) {
    headers["Content-Type"] = "application/json;charset=utf-8";
  }
  if (isAuth) {
    headers.token = localStorage.getItem("token");
    headers.userId = localStorage.getItem("userId");
  }
  return headers;
};

export { apiHost, streamHost, headers };
