import React, { Fragment } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  useNotify,
  fetchStart,
  fetchEnd,
  Button,
  Confirm,
  downloadCSV,
  useTranslate,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import querystring from "querystring";

import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";

import { apiHost, headers } from "./common";
import { DownloadBlobFile } from "./helper";

const ExporterButton = ({ props, url, csvHeader, fileName, isCsv = true }) => {
  const { filterValues, currentSort } = props;
  filterValues._sort = currentSort.field;
  filterValues._order = currentSort.order;

  const dispatch = useDispatch();
  const translate = useTranslate();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const handleSubmit = () => {
    dispatch(fetchStart());
    fetch(`${apiHost}/api/${url}?${querystring.stringify(filterValues)}`, {
      method: "GET",
      headers: headers(),
    })
      .then(async (result) => {
        if (isCsv) {
          return result.json();
        } else {
          return result.blob();
        }
      })
      .then((data) => {
        if(typeof data === "string"){
          notify("Export Error:" + data, "warning");
        } else {
          const file = fileName + "-" + moment().format("YYYY-MM-DDTHH:mm:ss");
        if (isCsv) {
          jsonExport(
            data,
            {
              rename: csvHeader,
            },
            (err, csv) => {
              const BOM = "\uFEFF";
              downloadCSV(`${BOM} ${csv}`, file);
            }
          );
        } else {
          DownloadBlobFile(data, `${file}.xlsx`);
        }
        }
      })
      .catch((e) => {
        notify("Export Error:" + e.message, "warning");
      })
      .finally(() => {
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    handleSubmit();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label={translate("ra.action.export")} onClick={handleClick}>
        <VerticalAlignBottomIcon />
      </Button>
      <Confirm
        isOpen={open}
        title={`匯出-${fileName}`}
        content={`確認匯出?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      ></Confirm>
    </Fragment>
  );
};

export { ExporterButton };
