import React from "react";
import Button from "@material-ui/core/Button";
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  translate,
  SelectInput,
} from "react-admin";

import { OperateCallback } from "../../../components/OperateCallback";

const validateCreation = (values) => {
  const errors = {};
  if (!values.role_name) {
    errors.role_name = "The role name is required";
  }
  if (!values.active_status && values.active_status !== 0) {
    errors.active_status = "The active status is required";
  }
  return errors;
};

const CreateToolbar = translate(({ onCancel, translate, ...props }) => {
  return (
    <Toolbar {...props}>
      <OperateCallback props={props} operate="create" />
      <Button onClick={onCancel}>{translate("ra.action.cancel")}</Button>
    </Toolbar>
  );
});

const SystemRoleCreate = ({ onCancel, ...props }) => (
  <Create title=" " {...props}>
    <SimpleForm
      validate={validateCreation}
      toolbar={<CreateToolbar onCancel={onCancel} />}
    >
      <TextInput source="role_name" label="ra.sources.role_name" />
      <SelectInput
        source="active_status"
        label="ra.sources.status"
        choices={[
          { id: 1, name: "ra.sources.enable" },
          { id: 0, name: "ra.sources.disable" },
        ]}
        defaultValue={1}
      />
    </SimpleForm>
  </Create>
);

export default SystemRoleCreate;
