import React, { useState } from "react";
import { Show, SimpleShowLayout, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DualListBox from "react-dual-listbox";
import { apiHost, headers } from "../../../components/common";

const useStyles = makeStyles({
  DualListWrapper: {
    padding: "20px 0 0 0",
  },
  DualListBox: {
    height: "450px",
  },
});

const CustomNameTextField = ({ record, source }) => {
  const translate = useTranslate();
  return `${translate("ra.sources.user")}: ${record[source]}`;
};

const DualListInput = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [selectedState, setSelectedState] = useState(
    record?.permission?.assignPermissions
  );

  const options = [
    {
      label: translate("ra.sources.role"),
      options: record?.permission?.roles || [],
    },
    {
      label: translate("ra.sources.permissions"),
      options: record?.permission?.permissions || [],
    },
  ];

  const changeHandler = (selected) => {
    setSelectedState(selected);
    const data = {
      userId: record.id,
      permissions: selected,
    };
    fetch(`${apiHost}/api/system/user/assign_role_permission`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers(),
    });
  };

  return (
    <div className={classes.DualListWrapper}>
      {record?.permission?.assign_permissions && (
        <DualListBox
          className={classes.DualListBox}
          canFilter
          options={options}
          selected={selectedState || record?.permission?.assign_permissions}
          onChange={changeHandler}
        />
      )}
    </div>
  );
};

const SystemUserAssignPermission = ({ onCancel, ...props }) => {
  return (
    <Show actions={false} {...props}>
      <SimpleShowLayout>
        <CustomNameTextField source="nickname" />
        <DualListInput />
      </SimpleShowLayout>
    </Show>
  );
};

export default SystemUserAssignPermission;
