// Chart.js
import React, { useState, useEffect, useRef } from 'react';
import { apiHost, headers } from "./common";

const Chart = ({ mac }) => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const url = `${apiHost}/api/air_noise/chart_data/${mac}`;
        const response = await fetch(url, { method: "GET", headers: headers(true, true) });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();

        if (isMounted) {
          setLabels(result.label);
          const airNoise = result.data.map(item => item.db);
          setData(airNoise);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [mac]);

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    chartInstanceRef.current = new window.Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: '噪音',
            data: data,
            fill: true,
            borderColor: 'rgb(55, 154, 255, 1)',
            backgroundColor: 'rgb(55, 154, 255, 0.7)',
            tension: 0.1
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '臺中市環保局環境檢驗科'
          }
        },
        pointBackgroundColor: '#fff',
        radius: 3,
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            title: {
              display: true,
              text: '日期(秒)'
            }
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: '分貝數(dB)'
            }
          }
        }
      }
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [labels, data]);

  return <canvas ref={chartRef} />;
};

export default Chart;
