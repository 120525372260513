import React, { Fragment, useCallback } from "react";
import { useNotify, useRedirect, useRefresh, SaveButton } from "react-admin";
import { apiHost, headers } from "./common";

const OperateCallback = ({ props, operate }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const handleSave = useCallback(
    (data) => {
      let method = "";
      let apiUrl = `${apiHost}/api${props.basePath}`;
      if (operate === "create") {
        method = "POST";
      } else if (operate === "edit") {
        method = "PUT";
        apiUrl += `/${props.record.id}`;
      }
      fetch(apiUrl, { method, body: JSON.stringify(data), headers: headers() })
        .then((result) => {
          if (result.status === 500) {
            return result.text();
          } else {
            return result.json();
          }
        })
        .then((rep) => {
          if (typeof rep === "object" && rep.message !== "") {
            notify(rep.message, rep.code === 0 ? "info" : "warning");
          } else {
            notify(rep, "warning");
          }
          if (rep.code === 0) {
            redirect(props.basePath);
            refresh();
          }
        });
    },
    [notify, redirect, refresh, props, operate]
  );
  return (
    <Fragment>
      <SaveButton {...props} onSave={handleSave} />
    </Fragment>
  );
};

export { OperateCallback };
