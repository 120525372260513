import React, { Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { Drawer, withStyles } from "@material-ui/core";
import { Route } from "react-router";
import compose from "recompose/compose";

import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  EditButton,
  Button,
  Filter,
  TextInput,
  useTranslate,
} from "react-admin";

import SystemUserCreate from "./SystemUserCreate";
import SystemUserEdit from "./SystemUserEdit";

import { CustomDateTimeField } from "../../../components/helper";

import SettingsIcon from "@material-ui/icons/Settings";

const styles = {
  drawerContent: {
    width: 300,
  },
};

const CustomTextField = ({ record, source }) => {
  const translate = useTranslate();
  let str = "";
  if (record && record[source] === 0) {
    str = <span className="badge">{translate("ra.sources.disable")}</span>;
  } else if (record && record[source] === 1) {
    str = (
      <span className="badge bg-green">{translate("ra.sources.enable")}</span>
    );
  }
  return str;
};

const OperationButton = ({ basePath, record }) => {
  return (
    <TopToolbar style={{ justifyContent: "flex-start" }}>
      <EditButton label="ra.action.edit" basePath={basePath} record={record} />
      <Button
        label="ra.action.deploy"
        component={Link}
        to={{ pathname: `/system/user/${record.id}/show` }}
      >
        <SettingsIcon />
      </Button>
    </TopToolbar>
  );
};

const SystemUserActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton label="ra.action.create" basePath={basePath} />
  </TopToolbar>
);

const SystemUserFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        label="ra.sources.email"
        source="email"
        resettable="true"
        alwaysOn
      />
    </Filter>
  );
};

class SystemUserList extends React.Component {
  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          empty={false}
          bulkActionButtons={false}
          title="ra.menu.system_user"
          actions={<SystemUserActions />}
          filters={<SystemUserFilters />}
        >
          <Datagrid>
            <TextField
              source="id"
              label="ra.sources.user_id"
              sortable={false}
            />
            <TextField
              source="nickname"
              label="ra.sources.nickname"
              sortable={false}
            />
            <TextField
              source="email"
              label="ra.sources.email"
              sortable={false}
            />
            <CustomTextField
              source="active_status"
              label="ra.sources.status"
              sortable={false}
            />
            <CustomDateTimeField
              source="create_time"
              label="ra.sources.create_time"
              sortable={false}
            />
            <OperationButton />
          </Datagrid>
        </List>
        <Route path="/system/user/create">
          {({ match }) => (
            <Drawer open={!!match} onClose={this.handleClose} anchor="right">
              <SystemUserCreate
                className={classes.drawerContent}
                onCancel={this.handleClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
        <Route path="/system/user/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== "create";

            return (
              <Drawer open={isMatch} onClose={this.handleClose} anchor="right">
                {isMatch ? (
                  <SystemUserEdit
                    className={classes.drawerContent}
                    id={match.params.id}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }
  handleClose = () => {
    this.props.push("/system/user");
  };
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(SystemUserList);
