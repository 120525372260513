// import * as React from "react";
// import { Card, CardHeader } from "@material-ui/core";
// import { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import Chart from '../components/Chart';

// export default () => (
//   <Card>
//     <CardHeader title="Welcome to the Admin" />
//   </Card>
// );

export default () => (
  <Card>
      <CardHeader title="Dashboard" />
      <CardContent>
        <Chart mac={"48:E7:29:AA:D2:CF"} />
      </CardContent>
    </Card>
);

// export default () => {
//   const history = useHistory();

//   useEffect(() => {
//     history.push('/monitor');
//   }, [history]);

//   return null;

  // return (
  //   <Card>
  //     <CardHeader title="Welcome to the Admin" />
  //   </Card>
  // )
// };

